.FifthContent {
  align-items: center;
  justify-content: center;
  padding: 120px 0px;
  row-gap: 20px;
  background: #f9fbfc;
}

.desText {
  width: 900px;
  text-align: center;
}

.accordion {
  width: 1000px;
  margin-top: 20px;
}

.accordinContent{
    
}

@media screen and (max-width: 1240px) {
  .desText {
    width: 630px;
  }

  .accordion {
    width: 650px;
  }
}

@media screen and (max-width: 600px) {
  .FifthContent {
    row-gap: 5px;
    padding: 50px 0px;
  }

  .desText {
    width: 300px;
  }

  .accordion {
    width: 320px;
    margin-top: 15px;
  }
}
