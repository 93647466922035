@import "../../../styles/stylesParams.module";

.forthContent {
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.bg {
    position: absolute;
    width: 600px;
}

.iconsWrapper {
    justify-content: center;
    align-items: center;
    width: 800px;
    flex-wrap: wrap;
    flex-direction: row;
}

.car {
  width: 320px;
  z-index: 2;
  margin: 0px -55px;
}

.textStack {
  width: 400px;
  row-gap: 25px;
}

.Button {
  padding: 16px 85px;
  border-color: #005b76;
  color: #005b76;
  border-radius: 16px;

  &:hover {
    font-weight: 800;
    background: #00475d;
    color:white;
  }
}

@media screen and (max-width: 1240px) {
  .forthContent {
    flex-direction: column;
    row-gap: 25px;
  }

  .car {
    width: 280px;
    margin: 0px -45px;
  }

  .iconsWrapper {
    width: 600px;
  }

  .bg {
    width: 520px;
  }

  .textStack {
    text-align: center;
    width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .Button {
    position: relative;
    padding: 6px 14px;
  }

  .bg {
    width: 300px;
  }

  .car {
    width: 150px;
    margin: 0px -25px;
  }

  .iconsWrapper {
    width: 350px;
    margin-top: 120px;
  }

  .textStack {
    width: 320px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
