@import "../../../styles/stylesParams.module";

.seventhContent {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: #f9fbfc;
    padding-top: 30px;
    padding-bottom: 30px;
}

.h1{
    text-align: center;
}

.textStack {
    text-align: center;
    width: 60%;
    row-gap: 25px;
}

@media screen and (max-width: 1240px) {
    .seventhContent {
        flex-direction: column;
        row-gap: 25px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .textStack {
        text-align: center;
        width: 600px;
    }
}

@media screen and (max-width: 600px) {
    .textStack {
        width: 320px;
        margin-top: 30px;
        margin-bottom: 50px;
    }
}