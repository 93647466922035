@import "../../styles/stylesParams.module";

.footer {
  flex-direction: column;
  padding: 50px 80px;
  align-items: center;
}

.wrapper {
  flex-direction: row;
  column-gap: 135px;
}

.coveryou {
  width: 300px;
  margin-bottom: 40px;
}

.leftStack {
  row-gap: 25px;
  width: 350px;
}

.mobile {
  margin-left: 20px;
}

.socialLinksStack {
  flex-direction: row;
  column-gap: 15px;
}

.socialIcon {
  font-size: 35px;
  cursor: pointer;
}

.linksStack {
  row-gap: 15px;
  margin-top: 126.5px;
}

.link {
  color: $neutrals500;
  text-decoration: none;

  &:hover {
    color: $neutrals700;
  }
}

.rightStack {
  flex-direction: row;
  column-gap: 50px;
}

.policiesRow {
  flex-direction: row;
  column-gap: 20px;
}

.policyStack {
  display: none;
}

.linkText {
    color: gray;
    text-decoration: none;
}
.linkText:hover{
    text-decoration: underline;
}

.insurerFriendly {
    width: 250px;
    height: auto;
    margin-bottom: 10px;
}

@media screen and (max-width: 1240px) {
  .coveryou {
    width: 230px;
    margin-bottom: 10px;
  }

  .linksStack {
    margin-top: 82px;
    row-gap: 5px;
  }

  .rightStack {
    column-gap: 30px;
  }

  .wrapper {
    column-gap: 50px;
  }

  .policiesRow {
    display: none;
  }

  .policyStack {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    margin-top: 35px;
  }

  .footer {
    padding: 50px 35px;
  }
}

@media screen and (max-width: 600px) {
  .coveryou {
    width: 220px;
  }

  .leftStack {
    width: 300px;
  }

  .rightStack {
    flex-direction: column;
    margin-top: 30px;
  }

  .policyStack {
    flex-direction: column;
    margin-top: 30px;
  }

  .wrapper {
    flex-direction: column;
  }

  .footer {
    align-items: flex-start;
  }

  .linksStack {
    margin-top: 0px;
  }
}
