@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('../assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: italic;
font-weight: 900;
src: url('../assets/fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
src: url('../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: italic;
font-weight: 700;
src: url('../assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 800;
src: url('../assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: italic;
font-weight: 800;
src: url('../assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 200;
src: url('../assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: italic;
font-weight: 200;
src: url('../assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: italic;
font-weight: 400;
src: url('../assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
src: url('../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: italic;
font-weight: 300;
src: url('../assets/fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
src: url('../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: italic;
font-weight: 500;
src: url('../assets/fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
src: url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: italic;
font-weight: 600;
src: url('../assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 100;
src: url('../assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
font-family: 'Poppins';
font-style: italic;
font-weight: 100;
src: url('../assets/fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
}

h1 {
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  line-height: 48px;
  letter-spacing: -0.8px;
}

h2 {
  font-family: "Poppins";
  font-size: 36px;
  font-style: normal;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}

h3 {
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  line-height: 40px; /* 125% */
  letter-spacing: -0.64px;
}

h4 {
  font-family: "Poppins";
  font-size: 28px;
  font-style: normal;
  line-height: 36px; /* 128.571% */
  letter-spacing: -0.56px;
}

h5 {
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.48px;
}

h6,
.h6 {
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}

big {
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  line-height: 28px; /* 155.556% */
  letter-spacing: -0.36px;
}

p,
.p {
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

small,
.small {
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.28px;
}

.pxs {
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
}

h1,
h2,
h3,
h4,
h5,
h6,
big,
p,
small,
.pxs {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.semiBold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

@media screen and (max-width: 1240px) {
  h1 {
    font-size: 30px;
  }

  h3 {
    font-size: 27px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 18px;
  }

  p,
  li {
    font-size: 12px;
    line-height: 16px;
  }
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 25px;
  }

  h6 {
    font-size: 16px;
  }

  big {
    font-size: 14px;
  }

  h5 {
    font-size: 18px;
  }
}
