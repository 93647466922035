@import "../../../styles/stylesParams.module";

.secondContent {
  background: #005b76;
  width: 100%;
  justify-content: center;
  align-items: center;
  row-gap: 80px;
  padding: 70px 0px;
}

.title {
  color: white;
  font-weight: 700;
  margin-bottom: 55px;
}

.icon {
    height: 124px;
}

.card {
    align-items: center;
    row-gap: $xsSpace;
}

.iconsStack {
  flex-direction: row;
  column-gap: 55px;
}

.cardTitle {
  color: white;
  font-weight: 700;
}

.cardSubTitle {
  color: $neutrals300;
}

.Button {
  padding: 16px 35px;
  border-color: $neutrals400;
  color: white;
  border-radius: 16px;

  &:hover {
    background: #00475d;
    color:white;
  }
}

@media screen and (max-width: 1240px) {
  .iconsStack {
    width: 680px;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 25px;
  }

  .title {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .icon {
    width: 80px;
    height: 80px;
  }

  .iconsStack {
    width: 350px;
  }

  .secondContent {
    row-gap: 50px;
  }

  .title {
    text-align: center;
    margin-bottom: 10px;
  }

  .Button {
    position: relative;
    padding: 6px 14px;
  }
}
