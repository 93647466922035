@import "../../../styles/stylesParams.module";

$lightBlack: #262626;

.thirdContent {
  background: #f9fbfc;
  width: 100%;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
  padding: 70px 0px;
}

.title {
  color: $lightBlack;
  font-weight: 700;
}

.card {
  align-items: center;
  row-gap: $xsSpace;
}

.iconsStack {
  flex-direction: row;
  column-gap: 125px;
}

.icon {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.cardSubTitle {
  color: $neutrals500;
  text-align: center;
  width: 300px;
}

@media screen and (max-width: 1240px) {
  .iconsStack {
    width: 680px;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 25px;
  }

  .icon {
    width: 180px;
    height: 180px;
  }
}

@media screen and (max-width: 600px) {
  .iconsStack {
    width: 350px;
  }

  .title {
    text-align: center;
    margin-bottom: 10px;
    padding: 0px 10px;
  }

  .icon {
    width: 120px;
    height: 120px;
  }

  .thirdContent {
    row-gap: 15px;
  }
}
