@import "../../styles/stylesParams.module";

.modalContentWrapper {
  padding: $xsSpace $largeSpace $smallSpace $largeSpace;
  background-color: $neutrals0;
  border-radius: 8px;
  max-height: 95vh;
  overflow-y: auto;
}

.colorBanner {
  width: 100%;
  height: 8px;
  position: absolute;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  left: 0px;
  top: -1px;
}

.colorBanner--green {
  background: $primary500;
}

.colorBanner--red {
  background: $secondaryPink600;
}

@media screen and (max-width: 600px) {
  .modalContentWrapper {
    width: 320px;
  }

  .modalContentWrapper {
    padding: $xsSpace $mediumSpace $smallSpace $mediumSpace;
    max-height: 400px;
    overflow-y: auto;
  }
}
