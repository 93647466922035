.content {
  padding: 50px 80px;
}

$tab: 16px;

.otherLegalReqItem {
  padding-left: $tab * 2;
  flex-direction: row;
  column-gap: $tab;
}

.oneTab {
  margin-left: $tab;
}

@media screen and (max-width: 1240px) {
  .content {
    padding: 20px 30px;
  }
}
