@import "../../styles/stylesParams.module";

.pink {
  color: $pink;
}

.firstContent {
  background: #f9fbfc;
  flex-direction: row;
  align-items: center;
}

.headerDes {
  font-size: 35px;
  font-weight: 800;
}

.getStartButton {
  background: #005b76;
  color: white;
  border-radius: 8px;
  padding: 8px 32px;
  margin-top: 55px;
  font-weight: bold !important;
  font-size: larger;
  border: 1px black solid;
  &:hover {
    background: #00475d;
    color:white;
  }
}

.contentWrapper {
  padding: 50px 50px 180px 50px;
  background: white;
  width: 600px;
}
.firstContentLogo {
    width: 600px;
}

.bmwIcon {
  width: 80px;
  height: 80px;
}

.aldiIcon,
.haval,
.kia,
.ford {
  width: 120px;
  height: 100px;
}

.lexusIcon {
  width: 120px;
  height: 100px;
}

.mercedesIcon {
  width: 80px;
  height: 80px;
}

.volvoIcon {
  width: 80px;
  height: 80px;
}

.toyotaIcon {
  width: 110px;
  height: 110px;
}

.haval {
  width: 120px;
  height: 100px;
}

.iconStackWrapper {
  width: 620px;
  overflow-x: hidden;
}

.offerBrand {
  margin-top: 30px;
}

.iconStack {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  column-gap: 55px;
  flex-direction: row;
  width: 620px;
  overflow-x: scroll;
  align-items: center;
  margin-top: 10px;
}

.iconStack::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.modalCloseButtonStack {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
}

.modal {
  row-gap: 20px;
}

.inputForm {
  row-gap: 15px;
}

.smallInput {
  width: 235px;
}

.inputGroup {
  flex-direction: column;
  row-gap: 8px;
}

.rowInputForm,
.accidentReportRow {
  flex-direction: row;
  justify-content: space-between;
}

.accidentStack {
  flex-direction: row;
  column-gap: 15px;
}

.selectButtonBase {
  padding-right: 20px;
  padding-left: 20px;
  height: 43px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 500;
}

.selectButton {
  @extend .selectButtonBase;
  border: 1px solid #d3d3d3;
  cursor: pointer;

  &:hover {
    border-color: #6e6a6a;
  }
}

.inputSubmitButton {
  background: #ff91e5;
  width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #00415c;

  &:hover {
    background: #ed63cd;
  }
}

.submitStack {
  align-items: center;
  row-gap: 15px;
  padding-bottom: $mediumSpace;
}

.selectButton--active {
  @extend .selectButtonBase;

  background: #00415c;
  color: white;
  border: unset;
}

.checkYourText {
  font-size: 45px;
  text-align: center;
}

.divider {
  margin: $mediumSpace 0px;
}

.titleStack {
  row-gap: $smallSpace;
  width: 100%;
}

.desStack,
.inputSuccessDesStack {
  row-gap: $mediumSpace;
}

@media screen and (max-width: 1240px) {
  .firstContent {
    flex-direction: column-reverse;
  }

  .firstContentLogo {
    width: 450px;
  }
  .headerDes {
    font-size: 30px;
  }

  .contentWrapper {
    padding: 50px 20px;
    width: 450px;
  }

  .desStack {
    align-items: center;
    text-align: center;
  }

  .firstContent {
    padding: 80px 0px;
  }
}

@media screen and (max-width: 600px) {
  .firstContentLogo {
    width: 350px;
  }

  .contentWrapper {
    padding: 20px 20px;
    width: 350px;
  }

  .iconStackWrapper,
  .iconStack,
  .desStack {
    width: 320px;
  }

  // .modal {
  //   width: 350px;
  // }

  .modalCloseButtonStack {
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: $smallSpace;
  }

  .smallInput {
    width: 130px;
  }

  .accidentReportRow {
    flex-direction: column;
    row-gap: 15px;
  }

  .modal {
    row-gap: 10px;
    margin-top: -10px;
  }

  .inputSubmitButton {
    width: 200px;
  }

  .submitPolicy {
    text-align: center;
  }

  .checkYourText {
    font-size: 30px;
  }

  .inputSuccessDesStack,
  .titleStack {
    width: unset;
  }

  .iconStack {
    column-gap: 8px;

    * {
      transform: scale(0.8);
    }
  }
}
