@import "../styles/stylesParams.module";

.purple {
  color: $purple;
}

.purpleLighter {
  color: $purpleLighter;
}

.purpleHover {
  color: $purpleHover;
}

.pink {
  color: $pink;
}

.neutrals600 {
  color: $neutrals600;
}

.neutrals500 {
  color: $neutrals500;
}

.neutrals400 {
  color: $neutrals400;
}

.neutrals300 {
  color: $neutrals300;
}

.error {
  color: #d94c4c;
}

.errorBorder {
  border-color: #d94c4c;
}

.fillScreen {
  min-height: calc(100vh - $headerHeight);
}

.link {
  color: #c8327d;
  cursor: pointer;
  font-weight: 600;
}

@media screen and (max-width: 1240px) {
  .fillScreen {
    min-height: calc(100vh - $headerHeightTablet);
  }
}

@media screen and (max-width: 600px) {
  .fillScreen {
    min-height: calc(100vh - $headerHeightMobile);
  }
}
