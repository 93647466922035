@import "../../../styles/stylesParams.module";

.sixthContent {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    overflow: hidden;
}

.imageWrapper {
    justify-content: center;
    align-items: center;
    width: 600px;
    flex-wrap: wrap;
    flex-direction: row;
}

.app {
    width: 600px;
    z-index: 2;
}

.textStack {
    width: 400px;
    row-gap: 25px;
}

@media screen and (max-width: 1240px) {
    .sixthContent {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .app {
        width: 300px;
    }
    .imageWrapper{
        width: 450px;
    }
    .textStack {
        text-align: center;
        width: 600px;
    }
    
}

@media screen and (max-width: 600px) {
    .textStack {
      width: 320px;
      margin-top: 30px;
      margin-bottom: 50px;
    }
    .imageWrapper{
      width: 320px;
    }
    .app {
        width: 320px;
        z-index: 2;
    }
    
}