// Spacings
$largeSpace: 40px;
$mediumSpace: 24px;
$smallMediumSpace: 16px;
$smallSpace: 12px;
$xsSpace: 8px;
$xssSpace: 4px;
// Colours
// primary
$primary50: #c8d9d6;
$primary100: #9bc7c1b2;
$primary200: #8ab8b1;
$primary300: #76a8a1;
$primary400: #549189;
$primary500: #447d75;
$primary600: #37665f;
$primary700: #294d47;
// secondary
$secondaryGreen500: #16a34a;
$secondaryBlue50: #afcaff4d;
$secondaryBlue500: #608bde;
$secondaryBlue600: #5880cc;
$secondaryYellow50: rgba(255, 241, 217, 0.6);
$secondaryYellow500: #f3bd61;
$secondaryPink600: #de625b;
$secondaryPink700: #d15c56;
$secondaryPink800: #bf544e;
// Neutrals
$neutrals0: #ffffff;
$neutrals50: #f9f9fa;
$neutrals100: #f5f5f5;
$neutrals200: #e5e5e5;
$neutrals300: #d4d4d4;
$neutrals400: #a3a3a3;
$neutrals500: #737373;
$neutrals600: #525252;
$neutrals700: #404040;
// Shades
$shades0: #fff;
$shades100: #1f1f1f;
// Destructive
$destructive100: #1f1f1f;
$destructive500: #b91c1c;
// others
$dividerGray: #e8e8e8;

$headerHeight: 121.81px;
$headerHeightTablet: 85.5px;
$headerHeightMobile: 70.66px;

$pink: #fc3898;
$purple: #1d0661;
$purpleLighter: #1c0561;
$purpleHover: #3410a1;

:export {
  pink: $pink;
  purple: $purple;
  purpleLighter: $purpleLighter;
  purpleHover: $purpleHover;
  // Spacings
  largeSpace: $largeSpace;
  mediumSpace: $mediumSpace;
  smallMediumSpace: $smallMediumSpace;
  smallSpace: $smallSpace;
  xsSpace: $xsSpace;
  xssSpace: $xssSpace;
  // Colours
  // primary
  primary100: $primary100;
  primary200: $primary200;
  primary300: $primary300;
  primary400: $primary400;
  primary500: $primary500;
  primary600: $primary600;
  primary700: $primary700;
  // secondary
  secondaryGreen500: $secondaryGreen500;
  secondaryBlue50: $secondaryBlue50;
  secondaryBlue500: $secondaryBlue500;
  secondaryYellow50: $secondaryYellow50;
  secondaryYellow500: $secondaryYellow500;
  secondaryPink600: $secondaryPink600;
  secondaryPink700: $secondaryPink700;
  secondaryPink800: $secondaryPink800;
  // neutrals
  neutrals0: $neutrals0;
  neutrals50: $neutrals50;
  neutrals100: $neutrals100;
  neutrals200: $neutrals200;
  neutrals300: $neutrals300;
  neutrals400: $neutrals400;
  neutrals500: $neutrals500;
  neutrals600: $neutrals600;
  neutrals700: $neutrals700;
  // Shades
  shades0: $shades0;
  shades100: $shades100;
  // Destructive
  destructive100: $destructive100;
  destructive500: $destructive500;
  // others
  dividerGray: $dividerGray;
  // dashboard height
  headerHeight: $headerHeight;
  headerHeightTablet: $headerHeightTablet;
}
