@import "../../styles/stylesParams.module";

.header {
  padding: 10px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 99;
}
.applyNowButton {
    font-family: Poppins;
    width: 180px;
    background: #005b76;
    color: white;
    border-radius: 8px;
    padding: 8px 32px;
    margin-top: 55px;
    font-weight: bold !important;
    font-size: medium;
    border: 1px black solid;
    &:hover {
      background: #00475d;
      color:white;
    }
}
.iconWrapper {
    align-items: center;
    height: 102px;
    justify-content: center;
}
.icon {
    width: 300px;
    cursor: pointer;
}
.buttonsStack {
  flex-direction: row;
  column-gap: 50px;
  align-items: center;
}
.contactButtonsStack {
  flex-direction: row;
  align-items: center;
}
.imgButton {
  &:hover {
    transform: scale(1.2);
  }
}
.phoneButton,
.emailButton {
  @extend .imgButton;
  cursor: pointer;
}
.phoneButton {
  width: 38px;
}
.textButton {
  color: rgb(95, 94, 94);
  cursor: pointer;
  font-size: 19px;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: black;
    text-decoration: underline;
  }
}
.menuButton {
  display: none;
}
.mobileHeaderMenu {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  background-color: #005b76;
  z-index: 99;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.mobileHeaderMenuCloseButtonStack {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 30px;
  padding-top: 10px;
  margin-right: 30px;
}
.mobileHeaderMenuButtonsStack {
    width: 100%;
    height: 100%;
    justify-content: start;
    align-items: start;
    row-gap: 15px;
    padding-left: 10px;
}
.mobileHeaderMenuButtonsStack a{
    font-size: x-large;
    font-weight: 400;
}
.mobileTextButton {
  font-family: "Poppins";
  font-size: 45px;
  color: $neutrals300;
  font-weight: 800;
  &:hover {
    color: $neutrals100;
  }
}
.closeIcon {
  color: white;
  font-size: 35px;
}
.iconButton {
  font-size: 60px;
  cursor: pointer;
  color: $neutrals300;
  &:hover {
    color: $neutrals100;
  }
}
@media screen and (max-width: 1240px) {
  .buttonsStack {
    display: none;
  }

  .icon {
    width: 225px;
  }

  .menuButton {
    display: unset;
  }
}
@media screen and (max-width: 600px) {
  .mobileHeaderMenuCloseButtonStack {
    padding-right: 2px;
    padding-top: 2px;
  }

  .icon {
    width: 150px;
  }

  .closeIcon {
    font-size: 25px;
  }

  .mobileTextButton {
    font-size: 30px;
  }

  .iconButton {
    font-size: 32px;
  }
}