@import "../../styles/stylesParams.module";
.modalHeader {
    height: 50px;
    direction: rtl;
}
.modalHeader .modalHeaderClose{
    padding: 5px;
    height: 40px;
    width: 40px;
}
.modalHeader .modalHeaderClose .closeIcon{
    color: gray;
    width: 32px;
    height: 32px;
}
.modalContainer{
    max-height: 400px !important;
}
.modalContainer .form{
    margin-top: 10px;
    margin-bottom: 10px;
}
.formControl {
    width: 100%;
}
.textField{
    border-radius: 120px;
}
.submitButton {
    margin-bottom: 30px;
    font-family: Poppins;
    width: 100%;
    background: #005b76;
    color: white;
    border-radius: 8px;
    padding: 8px 32px;
    font-weight: bold !important;
    font-size: medium;
    border: 1px black solid;
    &:hover {
      background: #00475d;
      color:white;
    }
}
.switchbutton {
    cursor: pointer;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 45px;
    padding: 5px;
    border: 1px gray solid;
    label {
        cursor: pointer;
        margin: 0;
    }
}
.switchbuttonactive {
    color: white;
    background-color: #005b76;
}
@media screen and (max-width: 1240px) {
    .desText {
      width: 630px;
    }
  
    .accordion {
      width: 650px;
    }
  }
  